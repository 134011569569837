import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { HeroSection } from '../components/common/HeroWithBg';
import Layout from "../components/layout";
import SEO from "../components/seo";

import Img from 'gatsby-image';

const OverlaySubscribe = styled(Container)`
background-color: rgba(250, 188, 61, 0.9);
  padding: 0px;
  margin: 0px;
`;

const Forma = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 320px;
  }
`;

export const query = graphql`
  query {
    file(relativePath: {eq: "naslovna-biografija.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Biografija = ({ data }) => {
  console.log(data.file.childImageSharp.fluid)
  return (
    <Layout pageInfo={{ pageName: "biografija" }}>
      <SEO title="Biografija" keywords={[`art`, `arhitektura`, `biografija`]} />

      <HeroSection>
        Radna Biografija
      </HeroSection>

      <Container mb-3="true">
        <Row>
          <Col xs={12} md={6}>
            <Img style={{ borderRadius: '10px' }} fluid={data.file.childImageSharp.fluid} alt="Biografija" />
          </Col>
          <Col xs={12} md={6}>
            <h2 className="display-3">Nada Jovičić Ivančajić(1963)</h2>
            <p>Studirala u Beogradu,generacija 1982/83-1990.</p>
            <p>Studentske nagrade:Oktobarska nagrada 1985,Nagrada beogradskog univerziteta(Prvomajska nagrada)1986, kao saradnik na Projektu enterijera IBM-ovog predstavništva u zgradi DIT-a,na Salonu arhitekture uz arh.Zorana Ristića dobijamo priznanje 1988.</p>
            <p>U periodu 1992/93 radim u projektnom birou “Naš Stan”,a od 1993 do danas u sopstvenom preduzeću “NZ”doo Beograd.Nagrade: Priznanje na salonu urbanizma 2011 za urbanistički projekat “Porta manastira Radovašnica” i Posebno priznanje za realizovani krov na izboru za “Najlepši krov Evrope”kompanije “Tondach”2012</p>
          </Col>
        </Row>

        <h2 className="my-4">Istaknuta dela iz oblasti:</h2>
        <h3><strong><em>Urbanizam</em></strong></h3>
        <div className="m-4">
          <h4>- UP-urbanitički projekti</h4>
          <p className="ml-2">
            MZ Aleksandrovo Subotica kompleks multi centar uz benzinsku pumpu “Glorija”2004, Poslovno sportski centar “Zorka”Šabac 2007,Tržnica AD Bajmok Subotica “Mega Mall” 2009 i urbanistički projekat “Porta manastira Radovašnica”kod Šapca 2011
          </p>
          <h4>- PDR</h4>
          <p className="ml-2">
            Plan detaljne regulacije opština Čibukovac Kraljevo 2006/07
          </p>
        </div>

        <h3><strong><em>Izvođenje</em></strong></h3>
        <div className="m-4">
          <p>
            Najznačajniji izvedeni objekat je sakralni objekat crkva Sv.arhanđela Mihaila I Gavrila u manastiru Radovašnica kod Šapca 2011/13 i Crkva Trojeručica u Manastiru Bogorodice Trojeručice na Avali
          </p>
        </div>

        <h3><strong><em>Projektovanje</em></strong></h3>
        <div className="m-4">
          <h4>- Individualni stambeni objekti</h4>
          <p className="d-block">
            Gostivarska 36/1994 naVoždovcu,Izvorska 69 na Čukarici /1995,vila na Zlatiboru 2008.
          </p>
        </div>
        <div className="m-4">
          <h4>- Stambeno poslovni objekti</h4>
          <p className="d-block">
            Hadži Đerina 12/2002 Vračar,Cerak ul. Pilota Mihajla Petrovića 79/2004,Dubljanska 96/2006 Vračar, na uglu M.Gavrilovića 6 i Južnog bul./2007 Vračar,stambeni objekat u Todora Dukina 63/2007 Voždovac, Golubačka 5/2008 na Zvezdari.
          </p>
        </div>
        <div className="m-4">
          <h4>- Poslovni objekti</h4>
          <p className="d-block">
            Tržno poslovni centar “Kocić” u Pilota Mihajla Petrovića 75/2006 Cerak i “Petrofarm”2009 Altina Zemun.
          </p>
        </div>
        <div className="m-4">
          <h4>- Sakralni objekti</h4>
          <p className="d-block">
            Crkva Sv. Arhangela Mihaila I Gavrila u manastiru Radovašnica/2011 i crkva brvnara Trojeručica u manastiru Trojeručica na Avali.
          </p>
        </div>

        <h3><strong><em>Primenjena umetnost</em></strong></h3>
        <div className="m-4">
          <p className="d-block">
            Ikonostas od mermera za crkvu manastira Radivašnica jedinstven po mermernim dverima 2013, arhivolte I nadvratni portali, stalak za celivajuću ikonu, intarzija granite I mermera u podu …
          </p>
        </div>
      </Container>

      <Container fluid className="subscribe-section no-p-m">
        <OverlaySubscribe fluid className="no-p-m text-center pt-5 pb-5 d-flex justify-content-center">
          <Row>
            <Col xs={12} md={4}>
              <h2 className="display-5 mb-2">Prijavite se na našoj listi</h2>
            </Col>
            <Col xs={12} md={8} className="d-flex">
              <form className="form-inline w-100" name="subskrajberi" method="POST" data-netlify="true" netlify-honeypot="bot-field">
                <p className="hidden sr-only">
                  <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                </p>
                <Forma className="form-group">
                  <label htmlFor="emailSubscription" className="sr-only">Email</label>
                  <input type="email" className="form-control form-control-lg w-100" id="emailSubscription" placeholder="Vaša email adresa" />
                </Forma>
                <button type="submit" className="btn btn-outline-secondary btn-lg">Prijavite se</button>
              </form>
            </Col>
          </Row>
        </OverlaySubscribe>
      </Container>

    </Layout >
  );
}

export default Biografija;

